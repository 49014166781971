.service {
  display: inline-block;
  width: 100%;
  background-color: #f1f1f1 !important;
  transition: all 300ms ease-in-out;
  color: #26466d;
  margin-bottom: 10px;
  text-align: left;
}

.service h4 {
  font-size: 1.25rem;
  position: relative;
  text-align: left !important;
}
.service h4::before {
  content: "";
  position: absolute;
  top: -3.5px;
  left: 0;
  width: 20px;
  height: 2px;
  background: #26466d !important;
}

@media (max-width: 767px) {
  .service {
    width: 95%;
    margin: 10px 0;
  }
}
