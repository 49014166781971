.non-mobile-div {
  height: 50%;
  text-align: center;
  margin-top: 25%;
}
.non-mobile-div span {
  font-size: 1rem;
  letter-spacing: 1px;
  margin: 0 1rem;
  color: white;
  border: 2px solid #f5b51a;
  border-radius: 2px 25px 2px 2px;
  cursor: pointer;
  transition: color 200s ease-in-out;
}
.email-navlink {
  display: none;
}
.activeNavlink {
  color: #f5b51a !important;
  transform: scale(1.1);
}
@media (min-width: 768px) {
  .non-mobile-div {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .non-mobile-div span {
    position: relative;
    color: white;
    padding: 10px;
    outline: 0;
    border: 2px solid transparent;
    font-size: 1.3rem;
    margin: 0 1rem;
    margin-top: 10px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
  }

  .non-mobile-div span::after {
    content: "";
    display: block;
    width: 100%;
    background-color: #f5b51a;
    border-radius: 5px;
    margin-top: 10px;
    height: 3px;
    transition: all 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .non-mobile-div span:hover::after {
    transform: scaleX(1);
  }
  .non-mobile-div span a {
    font-family: "Alegreya Sans", sans-serif;
    font-size: 1.6rem;
    font-style: oblique;
    font-weight: bolder;
  }
}

@media (max-width: 767px) {
  .non-mobile-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .non-mobile-div span {
    margin: 1.5rem auto;
    font-size: 2rem;
    border: 0;
  }
  .non-mobile-div span a {
    transition: color 300ms ease-in-out;
    font-family: "Paytone";
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .email-navlink {
    display: block;
    font-size: 2.5rem !important;
    padding: 0 !important;
  }
  .email-navlink a {
    font-family: "Work Sans", sans-serif !important;
    text-transform: none !important;
    letter-spacing: normal !important;
    color: #f5b51a !important;
  }
}
