.civil-law,
.criminal-law,
.labour-law,
.tort-law,
.commercial-law,
.administrative-law,
.international-law {
  width: 30%;
  text-align: left;
  padding: 1rem;
  min-height: 188px;
}

.international-law {
  min-height: auto !important;
}

@media (max-width: 767px) {
  .civil-law,
  .criminal-law,
  .labour-law,
  .tort-law,
  .commercial-law,
  .administrative-law,
  .international-law {
    width: 95%;
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
    min-height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .civil-law,
  .criminal-law,
  .labour-law,
  .tort-law,
  .commercial-law,
  .administrative-law,
  .international-law {
    width: 45%;
  }
}
