.subservice {
  font-size: 1.1rem;
  margin: 10px auto;
  text-align: left;
  margin-left: 1rem;
  color: #26466d;
}
.subservice i {
  margin-right: 5px;
  color: #f5b51a;
  font-weight: 500;
}

@media (max-width: 767px) {
  .subservice {
    font-size: 1rem;
  }
}
