header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 10vh;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #26466d, #1e3857);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.26);
}

.logo-div {
  cursor: pointer;
  text-align: left;
  color: white;
  padding-left: 1rem;
}
.logo-div span {
  font-family: "Belleza";
  font-size: 1rem;
}

.logo-div h1 {
  font-size: 2rem;
  font-family: "Kaushan Script", cursive;
  font-weight: 400;
  text-align: left;
  letter-spacing: 2px;
}
.logo-div h1 i {
  font-size: 1.5rem;
}

.navbar {
  height: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center;
  padding-right: 1rem;
}
.navbar .non-mobile-div {
  display: none;
}

.burger-btn {
  outline: none;
  width: 3.25rem;
  height: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
}

.burger-btn p {
  width: 3.25rem;
  height: 4.5px;
  background: white;
  border-radius: 2px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}

.top-line {
  width: 3.75rem !important;
  transform: rotate(45deg);
  transform-origin: 20% 100%;
}
.mid-line {
  width: 0 !important;
  height: 0 !important;
}

.bottom-line {
  width: 3.75rem !important;
  transform: rotate(-45deg);
  transform-origin: 10% 1%;
}

.remove-scroll {
  overflow: hidden !important;
}

@media only screen and (max-width: 767px) {
  .logo-div {
    padding-left: 1.5rem;
  }
  .navbar {
    padding-right: 1.5rem;
  }
  .logo-div span {
    display: none;
  }
  .logo-div h1 {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .burger-btn {
    display: none;
  }
  .navbar .non-mobile-div {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
