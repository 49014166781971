.bio-div {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bio-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.bio-header {
  background-color: #26466d;
  color: white;
}
.tulja,
.niksa {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.tulja {
  background-color: #26466d;
  color: white;
}
.niksa {
  background-color: white;
  box-shadow: 5px 4px 2px -2px black;
}

.img-holder,
.text-holder {
  display: flex;
  flex-direction: column;

  align-items: center !important;
  justify-content: center !important;
}
.img-holder {
  width: 30%;
  height: 300px;
  margin-top: 1rem;
  position: relative;
}

.img-holder img {
  width: 250px;
  height: 300px;
  border-radius: 2px 25px 2px 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.55);
}

.img-email {
  width: 250px;
  height: 300px;
  position: absolute;
  top: 300px;
  border-radius: 2px 25px 2px 2px;
  background-color: #3d3f42;
  z-index: 10;
  opacity: 0;
  transition: all 300ms ease;
  box-shadow: 5px 5px 10px black;
}
.img-email a {
  position: absolute;
  bottom: 5%;
  left: 100px;
  font-size: 50px;
  color: white;
  transition: all 200ms ease;
}

.hoverImg {
  opacity: 0.85;
  top: 0;
}
.img-email a:hover {
  color: #f5b51a;
  cursor: pointer;
}
.text-holder {
  width: 45%;
}

.text-holder h3 {
  margin: 1rem auto;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  color: white;
  font-style: italic;
}
.niksa .text-holder h3 {
  color: #26466d;
}

.text-holder p {
  position: relative;
  text-align: justify;
  padding: 1rem;
  vertical-align: middle;
  font-size: 1.25rem;
}
.text-holder p::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 1rem;
  width: 15%;
  height: 5px;
  background: white !important;
}
.text-holder .second-text::before {
  background: #26466d !important;
}
.text-holder span {
  font-size: 1.2rem;
  margin: 10px auto;
}
.mailer {
  display: inline;
  font-weight: bold;
  text-decoration: none !important;
  color: #f5b51a !important;
  transition: all 300ms ease-in-out;
}
.mailer:hover {
  color: #dca217 !important;
}
@media (max-width: 767px) {
  .bio-header {
    padding-bottom: 2rem !important;
  }
  .tulja,
  .niksa {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 0;
  }
  .niksa {
    flex-direction: column-reverse;
  }
  .text-holder {
    width: 90%;
    padding: 0;
  }
  .img-holder h3,
  .text-holder h3 {
    font-size: 1.5rem;
  }
  .img-holder {
    margin: 1.25rem auto;
    width: 200px;
    height: 250px;
  }

  .text-holder p {
    padding: 5px;
    font-size: 1.1rem;
    text-justify: newspaper;
  }

  .text-holder p::before {
    left: 5px;
  }
}
@media (max-width: 1050px) {
  .img-holder img {
    width: 200px;
    height: 250px;
  }

  .img-email {
    width: 200px;
    height: 250px;
  }
  .img-email a {
    left: 75px;
  }
}
@media (min-width: 768px) and (max-width: 1050px) {
  .hoverImg {
    top: 25px;
  }
}
