.intro {
  min-height: 90vh !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  position: relative;
  background-color: #f1f1f1 !important;
}

.intro-anim {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to right, #26466d, #1e3857);
  color: white;
}
.intro-anim img {
  width: 150px;
  height: 150px;
}
.intro-anim p {
  margin: 10px auto;
  font-size: 1.25rem;
}
.intro-anim #intro-anim-title {
  height: 4.5rem;
  font-size: 4.5rem;
  font-family: "Kaushan Script", cursive;
  font-weight: bold;
  letter-spacing: 5px;
}

.intro-anim-show {
  opacity: 1;
}
.intro h2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem;
}
.intro-text {
  position: relative;
  margin-top: 1rem;
  padding: 5px;
  font-size: 1.2rem;
  width: 100%;
  color: #26466d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.intro-text p {
  text-align: justify;
  width: 55%;
  position: relative;
}
.intro-text img {
  width: 300px;
  height: 200px;
  border-radius: 5px 25px 5px 5px;
  box-shadow: 0 0 5px gray;
}
.boss-img {
  margin-left: 2rem;
}
.boss-img span {
  display: block;
  font-size: 1rem;
  margin: 0 auto;
  text-align: center;
}

.intro-text p::before {
  content: "";
  position: absolute;
  top: -20px;
  width: 15%;
  height: 5px;
  background: #26466d !important;
}

.intro .button-holder {
  width: 100%;
}
.jump-to-contact {
  position: relative;
  width: 50%;
  margin: 2rem auto;
  padding: 1rem 3rem;
  outline: 0;
  border: 0;
  color: black;
  background: #627994;
  border-radius: 2px 25px 2px 2px;
  cursor: pointer;
  font-size: 1.85rem;
  transition: all 300ms ease-in-out;
}
.jump-to-contact::before {
  transition: all 300ms ease-in-out;
  content: "";
  width: 0;
  height: 100%;
  background: #3b587b;
  position: absolute;
  top: 0;
  left: 0;
}
.jump-to-contact span {
  mix-blend-mode: darken;
  color: black;
}
.jump-to-contact a {
  font-family: "Paytone" !important;
  font-weight: bolder;
}
.jump-to-contact:hover::before {
  width: 100%;
  border-radius: 2px 25px 2px 2px;
}

@media (max-width: 767px) {
  .intro {
    margin-top: 10vh;
  }
  .intro-anim p {
    font-size: 1rem;
  }
  .intro-anim img {
    width: 100px;
    height: 100px;
  }
  .intro-anim #intro-anim-title {
    font-size: 2.5rem;
    height: 2.5rem;
  }
  .intro h2 {
    padding-top: 1rem;
  }
  .intro-text {
    width: 95%;
    margin: 0 auto;
    padding: 5px;
    flex-direction: column;
  }
  .intro-text p {
    width: 100%;
    font-size: 1.1rem;
    text-justify: newspaper;
  }
  .intro-text p::before {
    top: -10px;
  }
  .intro-text img {
    width: 230px !important;
    height: 160px !important;
  }
  .boss-img {
    margin-left: 0;
    margin-top: 1rem;
  }

  .jump-to-contact {
    width: 75%;
    margin: 1.5rem auto;
  }
}

@media (max-width: 1160px) {
  .jump-to-contact {
    letter-spacing: 1px;
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .jump-to-contact {
    font-size: 1.1rem;
    letter-spacing: normal;
  }
}

@media (max-width: 400px) {
  .intro-text {
    font-size: 14px !important;
  }
  .jump-to-contact {
    width: 95%;
    padding: 1rem;
  }
}
