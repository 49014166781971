@import url(https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500);
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@700&display=swap");

@font-face {
  font-family: "Paytone";
  src: url("./assets/Paytone.ttf") format("truetype");
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: #f1f1f1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smooth: antialiased;
}

header,
footer {
  color: white;
  font-weight: bold;
}

h2 {
  color: #26466d;
  font-family: "Alegreya Sans", sans-serif;
  font-size: 3.2rem;
  padding-top: 10vh;
  font-weight: bolder;
  text-transform: uppercase;
  font-style: oblique;
  position: relative !important;
}
h2::after {
  content: "";
  display: block;
  width: 60px;
  height: 2px;
  margin: 0 auto;
  margin-top: 7.5px;
  background: #f5b51a;
}

button {
  font-family: "Paytone" !important;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 279px) {
  body {
    display: none;
  }
}
