.danger,
.success {
  font-weight: bold;
  transition: all 200ms ease-in-out;
  font-size: 1rem !important;
}
.danger {
  color: #cf352e;
}
.success {
  display: inline-block !important;
  color: #228b22;
}
