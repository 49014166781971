.contact-div {
  min-height: 85vh;
  width: 100%;
  background: #f1f1f1;
}
.contact-div h2 {
  padding-bottom: 2rem !important;
}
.contact-content {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.map-div,
.info-form-div {
  width: 100%;
}
.map-div {
  height: 450px;
}
.map-holder {
  width: 100%;
  height: 450px;
  box-shadow: 3px 1px 4px rgba(0, 0, 0, 0.26);
}
.info-form-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.information,
.form-div {
  width: 45%;
}

.information h5 {
  font-size: 1.25rem;
  margin: 2rem auto;
  text-align: left !important;
}
.information p {
  position: relative;
  margin-top: 2rem;
  padding-top: 10px;
  font-size: 1.15rem;
  text-align: left;
}
.information p::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 15%;
  height: 5px;
  background: #26466d !important;
}
.website {
  text-decoration: none;
  color: black;
  transition: all 300ms ease-in-out;
}
.website:hover {
  color: #26466d;
}
form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

label {
  margin-bottom: 10px;
  font-size: 1.25rem;
  text-align: left;
}

label span {
  font-size: 1rem;
}
input,
textarea {
  padding: 1rem;
  outline: 0;
  text-align: left;
  border: 0;
  border-radius: 3px 15px 3px 3px;
  margin-bottom: 10px;
  transition: all 300ms ease-in-out;
  border: 1px solid #3d3f42;
}
textarea {
  margin-bottom: 1rem;
}
input {
  height: 2rem;
}
input:focus,
textarea:focus {
  box-shadow: 0 0 6px #26466d;
}
.errors-div {
  text-align: left;
  height: 1.25rem;
  margin-bottom: 10px;
}
.button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

form button {
  position: relative;
  text-align: center;
  outline: 0;
  border: 0;
  padding: 1rem;
  width: 60%;
  margin: 1rem;
  border-radius: 2px 25px 2px 2px;
  background: #627994;
  color: #26466d;
  cursor: pointer;
  font-size: 1.9rem;
  transition: all 300ms ease-in-out;
}
form button::before {
  transition: all 300ms ease-in-out;
  content: "";
  width: 0;
  height: 100%;
  background: #3b587b;
  position: absolute;
  top: 0;
  left: 0;
}

form button span {
  mix-blend-mode: darken;
  font-family: "Paytone";
  color: black;
  font-weight: bolder;
}
form button:hover:before {
  width: 100%;
  border-radius: 2px 25px 2px 2px;
}

form label i,
.information h5 i {
  color: #26466d;
  font-size: 1.3rem;
  font-weight: 500 !important;
}
.information h5 a {
  background: linear-gradient(to bottom, #26466d 0%, #26466d 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  padding-left: 5px;
  padding-right: 5px;
  background-size: 0 0;
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-out;
}
.information h5 a:hover {
  color: #f1f1f1;
  background-size: 5px 50px;
}

@media (max-width: 767px) {
  .info-form-div {
    flex-direction: column;
  }
  .information {
    width: 90%;
  }
  .information h5 {
    font-size: 1.1rem;
  }
  .information p {
    margin-bottom: 1rem;
    padding-top: 5px;
    font-size: 1.1rem;
  }
  .form-div {
    width: 90%;
    margin-top: 10px;
  }
  form button {
    font-size: 1.35rem;
    width: 75%;
  }
  label {
    font-size: 1.1rem;
  }

  form label i,
  .information h5 i {
    font-size: 1.2rem;
  }
}
