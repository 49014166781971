#to-top-btn {
  cursor: pointer;
  height: 60px;
  width: 60px;
  position: fixed;
  z-index: 100;
  padding: 5px;
  transition: all 300ms ease-in-out;
}

#up-arrow {
  color: #26466d;
  font-size: 4rem;
  filter: drop-shadow(2px 2px 5px white);
}

@keyframes upBtn {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.85);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

#to-top-btn:hover {
  animation: upBtn 2000ms infinite;
}
