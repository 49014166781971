.services-div {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
}
.img-parallax {
  width: 100%;
  min-height: 400px;
  background-image: url("../../assets/office.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.services-list {
  background-image: url("../../assets/court.png");
  background-position: 50% 30%;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #f1f1f1;
}
.services-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f1f1f1;
  opacity: 0.85;
  z-index: 10;
}
.services-div h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
  .services-list {
    flex-direction: column;
    width: 95%;
    padding: 0;
    margin: 10px auto;
    background-position: 50% 50%;
  }
  .services-holder {
    width: 95%;
  }
  .img-parallax {
    min-height: 220px !important;
    background-attachment: scroll;
  }

  .second-parallax {
    display: none;
  }
}
