footer {
  width: 100%;
  min-height: 15vh;
  padding: 10px;
  background: #3d3f42;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400 !important;
}
.links-contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.links {
  display: flex;
  flex-direction: column;
}
.links span {
  color: #f5b51a;
  cursor: pointer;
  letter-spacing: 1px;
}
.links span a {
  font-family: "Work Sans";
}
.links span:hover {
  color: white;
}
.contact h4 {
  font-weight: 400;
}
.contact h4 i {
  font-size: 1rem;
}
.dev {
  color: white;
}
.footer-mail {
  color: white !important;
  font-weight: normal;
}
.phone-link {
  text-decoration: none;
  color: white;
  transition: all 300ms ease-in-out;
}
.phone-link:hover {
  color: #dca217 !important;
}
.dev p a {
  text-decoration: none;
  color: white;
  transition: all 300ms ease-in-out;
}
.dev p a:hover {
  color: #f5b51a;
}

@media (max-width: 767px) {
  footer {
    font-size: 0.9rem !important;
  }
  .links-contact {
    flex-direction: column;
  }
  .links {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    font-size: 1rem;
  }
  .contact {
    margin: 5px 0;
  }
  .contact h4 {
    margin: 3px 0;
  }
  .links span {
    letter-spacing: normal;
  }
}

@media (min-width: 767px) {
  .dev p {
    margin: 10px 0;
  }
  .links span {
    margin: 5px 0;
  }
  .contact h4 {
    margin: 10px 0;
  }
}
