.aside {
  position: fixed;
  overflow: hidden;
  right: 0;
  top: 10%;
  z-index: 100;
  height: 100vh;
  width: 100%;
  background: #26466d;
  overflow: hidden;
}
