.languages .lang-buttons button {
  font-size: 1rem;
  font-family: "Work Sans" !important;
  outline: 0;
  color: white;
  border: 0;
  background: #1e3857;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.languages .lang-buttons span {
  height: 100%;
  border: 1px solid white;
}
.languages .lang-buttons button:first-child {
  margin-right: 5px;
  padding-right: 5px;
}
#eng-button {
  margin-left: 5px;
  padding-left: 5px;
}
.languages .lang-buttons button:hover {
  color: #ba9508;
}
.activeLanguage {
  font-weight: bold;
  color: #f5b51a !important;
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  header .navbar .languages {
    display: none;
  }
  .lang-buttons {
    text-align: center;
    padding-top: 20px;
  }
  .languages .lang-buttons button {
    font-size: 1.8rem;
    background: #26466d !important;
  }
  .languages .lang-buttons button:first-child {
    margin-right: 15px;
    padding-right: 15px;
  }
  #eng-button {
    margin-left: 15px !important;
    padding-left: 15px !important;
  }
  .languages .lang-buttons span {
    padding: 2px;
    background-color: #fff;
  }
  .activeLanguage {
    transform: scale(1.25);
  }
}
